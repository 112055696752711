<template>
  <component
    :is="tag"
    :class="['input-field', classComputed]"
  >
    <slot
      v-if="$slots.prepend"
      name="prepend"
    />

    <input
      :id
      ref="inputRef"
      v-maska
      :value
      :type
      :inputmode
      :name
      :readonly
      :disabled
      :data-maska="mask"
      v-bind="$attrs"
      @input="onInput"
      @change="onChange"
      @focus="onFocus"
      @click="onFocus"
      @blur="onBlur"
      @keydown.enter="onEnter"
    />

    <Button
      v-if="clearable && (maska ? !!unmaskedValue : !!modelValue)"
      class="input-field__clear"
      iconOnly
      iconName="close"
      @click="clear"
    />

    <slot />
  </component>
</template>

<script lang="ts" setup>
import { defu } from 'defu';
import { Button } from '@shared/Button';

interface IProps {
  modelValue?: string | number | null;
  variant?: 'secondary' | 'primary';
  size?: 'sm' | 'lg';
  id?: string | number;
  name?: string;
  type?: string;
  inputmode?: 'text' | 'none' | 'search' | 'email' | 'url' | 'tel' | 'numeric' | 'decimal';
  outline?: boolean;
  loading?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  block?: boolean;
  clearable?: boolean;
  autofocus?: boolean;
  autofocusDelay?: number;
  mask?: string;
  error?: boolean;
  tag?: string | Object;
  modelModifiers?: { trim?: boolean; lazy?: boolean; number?: boolean };
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: '',
  tag: 'div',
  type: 'text',
  inputmode: undefined,
  autofocusDelay: 300,
  id: undefined,
  size: undefined,
  variant: undefined,
  name: undefined,
  mask: undefined,
  modelModifiers: () => ({}),
});

const propsParent = (inject('form-group', null) as any) ?? {};

const $emit = defineEmits(['update:modelValue', 'input', 'change', 'blur', 'focus', 'clear', 'enter']);

const inputRef = ref();

const modelModifiers = ref(defu({}, props.modelModifiers, { trim: false, lazy: false, number: false }));

const id = computed(() => propsParent?.id?.value ?? props?.id);
const name = computed(() => propsParent?.name?.value ?? props?.name);
const block = computed(() => propsParent?.block?.value ?? props?.block);
const error = computed(() => propsParent?.error?.value ?? props?.error);

const { maska, unmaskedValue, maskedValue } = props.mask ? useMask(props.mask, props.modelValue) : ({} as any);

const value = computed(() => props.modelValue || maskedValue || '');

const focused = ref(false);

const classComputed = computed(() => {
  const classes = [
    {
      'is-focus': focused.value,
      'is-outline': props.outline,
      'iis-loading': props.loading,
      'is-disabled': props.disabled,
      'is-readonly': props.readonly,
      'is-clearable': props.clearable,
      'is-block': block.value,
      'is-error': error.value,
    },
    props.size && `input-field--${props.size}`,
    props.variant && `input-field--${props.variant}`,
  ];

  return classes;
});

const onInput = ($event: any) => {
  if (!modelModifiers.value.lazy) {
    updateInput($event.target.value);
  }

  $emit('input', $event);
};

const onChange = ($event: Event) => {
  if (props.type === 'file') {
    const value = ($event.target as HTMLInputElement).files;

    $emit('change', value);
  } else {
    const value = ($event.target as HTMLInputElement).value;

    $emit('change', value);

    if (modelModifiers.value.lazy) {
      updateInput(($event.target as HTMLInputElement).value);
    }

    if (modelModifiers.value.trim) {
      ($event.target as HTMLInputElement).value = value.trim();
    }
  }
};

const onFocus = ($event: any) => {
  focused.value = true;

  $emit('focus', $event);

  setTimeout(() => {
    if (props.mask && maskedValue && inputRef.value?.selectionStart < maskedValue?.length) {
      inputRef.value.selectionStart = $event.target.value?.length;
      inputRef.value.selectionEnd = $event.target.value?.length;
    }
  }, 10);
};

const onBlur = ($event: any) => {
  focused.value = false;

  $emit('blur', $event);
};

const onEnter = (): void => {
  $emit('enter');
};

const updateInput = (inputValue: any) => {
  if (modelModifiers.value.trim) {
    inputValue = String(inputValue).trim();
  } else if (modelModifiers.value.number || props.type === 'number') {
    inputValue = toNumber(inputValue);
  }

  $emit('update:modelValue', inputValue);
};

const clear = () => {
  updateInput('');
  inputRef?.value?.focus();
  $emit('clear');
};

defineOptions({
  inheritAttrs: false,
});

defineExpose({
  inputRef,
});

onMounted(() => {
  if (props.autofocus) {
    setTimeout(() => {
      inputRef.value?.focus();
    }, props.autofocusDelay);
  }
});
</script>

<style lang="scss" src="./Input.scss" />
